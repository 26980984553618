import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

//gambar
import AddOutlined from '@mui/icons-material/AddOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

function Produk(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [data_produk, setDataProduk] = useState([]);
    const [total, setTotal] = useState(0);
    const [pencarian, setPencarian] = useState("");
    const [data_jenis, setDataJenis] = useState([]);
    const [jenis, setJenis] = useState(null);
    const [input_jenis, setInputJenis] = useState("");
    const [id_produk, setIdProduk] = useState("");
    const [nama, setNama] = useState("");
    const [kode, setKode] = useState("");
    const [harga_beli, setHargaBeli] = useState(0);
    const [harga_jual, setHargaJual] = useState(0);
    const [harga_jual_member, setHargaJualMember] = useState(0);
    const [satuan, setSatuan] = useState("");
    const [stok_minimal, setStokMinimal] = useState(0);
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);
    const [tampil_dialog_cetak, setTampilDialogCetak] = useState(false);
    const [jumlah_cetak, setJumlahCetak] = useState(1);
    const [jumlah_baris] = useState(40);
    const [jumlah_halaman, setJumlahHalaman] = useState(0);
    const [halaman, setHalaman] = useState(1);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/produk/tampil_produk_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "",
                        cari: "",
                        jumlah_baris: 40,
                        halaman: 1
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataProduk(data.data);
                    setTotal(data.total);
                    setJumlahHalaman(Math.ceil(data.jumlah_data / 40));
                }
                else {
                    setDataProduk([]);
                    setTotal(0);
                    setJumlahHalaman(0);
                }
            })
            .catch(error => {
                setDataProduk([]);
                setTotal(0);
                setJumlahHalaman(0);
            }
            );

        fetch(props.aplikasi + '/produk/tampil_jenis_produk.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataJenis(data.data);
                }
                else {
                    setDataJenis([]);
                }
            })
            .catch(error => {
                setDataJenis([]);
            }
            );
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_produk = (halaman_baru) => {
        fetch(props.aplikasi + '/produk/tampil_produk_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "",
                        cari: pencarian,
                        jumlah_baris: jumlah_baris,
                        halaman: halaman_baru
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataProduk(data.data);
                    setTotal(data.total);
                    setJumlahHalaman(Math.ceil(data.jumlah_data / jumlah_baris));
                }
                else {
                    setDataProduk([]);
                    setTotal(0);
                    setJumlahHalaman(0);
                }
            })
            .catch(error => {
                setDataProduk([]);
                setTotal(0);
                setJumlahHalaman(0);
            }
            );
    }

    const pindah_halaman = async (halaman_baru) => {
        setHalaman(halaman_baru);
        tampil_produk(halaman_baru);
    }

    const cari = () => {
        setHalaman(1);
        tampil_produk(1);
    }

    const bersih_input_produk = () => {
        setJenis(null);
        setInputJenis("");
        setIdProduk("");
        setNama("");
        setKode("");
        setHargaBeli(0);
        setHargaJual(0);
        setHargaJualMember(0);
        setSatuan("");
        setStokMinimal(0);
        setStatus(false);
        setLabelStatus("Nonaktif");
    }

    const ubah_harga_beli = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setHargaBeli(event.target.value);
    }

    const perbaiki_harga_beli = (event) => {
        if (event.target.value.trim() === "") {
            setHargaBeli(0);
        }
    }

    const ubah_harga_jual = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setHargaJual(event.target.value);
    }

    const perbaiki_harga_jual = (event) => {
        if (event.target.value.trim() === "") {
            setHargaJual(0);
        }
    }

    const ubah_harga_jual_member = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setHargaJualMember(event.target.value);
    }

    const perbaiki_harga_jual_member = (event) => {
        if (event.target.value.trim() === "") {
            setHargaJualMember(0);
        }
    }

    const ubah_stok_minimal = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setStokMinimal(event.target.value);
    }

    const perbaiki_stok_minimal = (event) => {
        if (event.target.value.trim() === "") {
            setStokMinimal(0);
        }
    }

    const simpan = () => {
        if (jenis === null) {
            setJenisNotif("warning");
            setIsiNotif("Jenis produk wajib diisi");
            setTampilNotif(true);
            document.getElementById("jenis").focus();
            return;
        }

        if (nama.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Nama produk wajib diisi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        if (parseFloat(harga_beli) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Harga pokok penjualan wajib diisi");
            setTampilNotif(true);
            document.getElementById("harga_beli").focus();
            return;
        }

        if (parseFloat(harga_jual) === "0") {
            setJenisNotif("warning");
            setIsiNotif("Harga jual wajib diisi");
            setTampilNotif(true);
            document.getElementById("harga").focus();
            return;
        }

        if (parseFloat(harga_jual_member) === "0") {
            setJenisNotif("warning");
            setIsiNotif("Harga jual wajib diisi");
            setTampilNotif(true);
            document.getElementById("harga").focus();
            return;
        }

        if (satuan.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Satuan produk wajib diisi");
            setTampilNotif(true);
            document.getElementById("satuan").focus();
            return;
        }

        setProses(true);
        if (id_produk === "") {
            fetch(props.aplikasi + '/produk/simpan_produk.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_produk: "",
                            id_jenis: jenis.id_jenis,
                            nama: nama,
                            kode: kode,
                            harga_beli: harga_beli,
                            harga_jual: harga_jual,
                            harga_jual_member: harga_jual_member,
                            satuan: satuan,
                            stok_minimal: stok_minimal,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_produk();
                        tampil_produk(1);
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/produk/simpan_produk.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_produk: id_produk,
                            id_jenis: jenis.id_jenis,
                            nama: nama,
                            kode: kode,
                            harga_beli: harga_beli,
                            harga_jual: harga_jual,
                            harga_jual_member: harga_jual_member,
                            satuan: satuan,
                            stok_minimal: stok_minimal,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_produk(1);
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
    }

    const hapus = () => {
        setProses(true);
        fetch(props.aplikasi + '/produk/hapus_produk.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_produk: id_produk
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_produk();
                    tampil_produk(1);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                document.getElementById("nama").focus();
                setProses(false);
            }
            );
    }

    const ubah_jumlah_cetak = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setJumlahCetak(event.target.value);
    }

    const perbaiki_jumlah_cetak = (event) => {
        if (event.target.value.trim() === "") {
            setJumlahCetak(1);
        }
    }

    const cetak = () => {
        if (kode === "") {
            setJenisNotif("warning");
            setIsiNotif("Barcode tidak boleh kosong");
            setTampilNotif(true);
            return;
        }

        window.open(props.aplikasi + '/produk/cetak_barcode.php?kode=' + kode + '&jumlah=' + jumlah_cetak);
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_produk();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <Typography variant="body1">Total Aset : Rp. {format_rupiah(parseFloat(total))}</Typography>

                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan nama produk atau scan barcode"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        value={pencarian}
                        onChange={(event) => setPencarian(event.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                cari();
                            }
                        }}
                    />
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_produk
                        .map((tampung_produk, index) => {
                            return (
                                <Grid
                                    item xs={12} sm={6} md={4} lg={3}
                                    key={index}
                                    onClick={() => {
                                        bersih_input_produk();
                                        setIdProduk(tampung_produk.id_produk);

                                        let jenis_produk = tampung_produk.id_jenis;
                                        let awal = 1;
                                        let akhir = data_jenis.length;
                                        while (awal <= akhir) {
                                            if (jenis_produk === data_jenis[awal - 1]["id_jenis"]) {
                                                setJenis(data_jenis[awal - 1]);
                                                break;
                                            }
                                            awal++;
                                        }

                                        setNama(tampung_produk.nama);
                                        setKode(tampung_produk.kode);
                                        setHargaBeli(tampung_produk.harga_beli);
                                        setHargaJual(tampung_produk.harga_jual);
                                        setHargaJualMember(tampung_produk.harga_jual_member);
                                        setSatuan(tampung_produk.satuan);
                                        setStokMinimal(tampung_produk.stok_minimal);
                                        if (tampung_produk.status === "1") {
                                            setStatus(true);
                                            setLabelStatus("Aktif");
                                        }
                                        else {
                                            setStatus(false);
                                            setLabelStatus("Nonaktif");
                                        }
                                        setTampilDialog(true);
                                    }}
                                >
                                    <Card
                                        sx={
                                            parseFloat(tampung_produk.jumlah) <= parseFloat(tampung_produk.stok_minimal)
                                                ? {
                                                    backgroundColor: "#f99f87"
                                                }
                                                : {}
                                        }
                                    >
                                        <List>
                                            <ListItem button>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body1" noWrap>{tampung_produk.nama}</Typography>
                                                    }
                                                    secondary={
                                                        <Typography variant="subtitle2" noWrap>{"Rp. " + tampung_produk.harga_beli2 + " x " + tampung_produk.jumlah2 + " " + tampung_produk.satuan + " = Rp. " + format_rupiah(tampung_produk.harga_beli * tampung_produk.jumlah)}</Typography>
                                                    }
                                                >
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </Card>
                                </Grid>
                            );
                        })}
                </Grid>

                <Grid
                    container item xs={12}
                    justifyContent="center"
                    sx={{
                        marginBottom: "10px"
                    }}
                >
                    <Stack spacing={2}>
                        <Pagination
                            color="primary"
                            count={jumlah_halaman}
                            page={halaman}
                            onChange={(event, nomor) => {
                                pindah_halaman(nomor);
                            }}
                        />
                    </Stack>
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Produk</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Jenis Produk
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <Autocomplete
                                        id="jenis"
                                        options={data_jenis}
                                        getOptionLabel={(option) => option.nama}
                                        inputValue={input_jenis}
                                        value={jenis}
                                        onInputChange={(e, nilai) => setInputJenis(nilai)}
                                        onChange={(e, nilai) => {
                                            setJenis(nilai);
                                        }}
                                        renderInput={(params) => <TextField {...params} key={params.id_jenis} variant="outlined" size="small" />}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Nama
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="nama"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Barcode
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="kode"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={kode}
                                        onChange={(event) => setKode(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Harga Beli
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} sm={8} md={9}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            id="harga_beli"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={harga_beli}
                                            onChange={(event) => ubah_harga_beli(event)}
                                            onBlur={(e) => perbaiki_harga_beli(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseFloat(harga_beli))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Harga Jual
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} sm={8} md={9}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            id="harga"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={harga_jual}
                                            onChange={(event) => ubah_harga_jual(event)}
                                            onBlur={(e) => perbaiki_harga_jual(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseFloat(harga_jual))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Harga Jual Member
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} sm={8} md={9}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            id="harga_jual_member"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={harga_jual_member}
                                            onChange={(event) => ubah_harga_jual_member(event)}
                                            onBlur={(e) => perbaiki_harga_jual_member(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseFloat(harga_jual_member))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Satuan
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <Select
                                        id="satuan"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={satuan}
                                        onChange={(event) => setSatuan(event.target.value)}
                                    >
                                        <MenuItem value="BH">BH</MenuItem>
                                        <MenuItem value="BIT">BIT</MenuItem>
                                        <MenuItem value="BJI">BJI</MenuItem>
                                        <MenuItem value="BOS">BOS</MenuItem>
                                        <MenuItem value="BOX">BOX</MenuItem>
                                        <MenuItem value="BTG">BTG</MenuItem>
                                        <MenuItem value="BTL">BTL</MenuItem>
                                        <MenuItem value="DUS">DUS</MenuItem>
                                        <MenuItem value="GLG">GLG</MenuItem>
                                        <MenuItem value="GRS">GRS</MenuItem>
                                        <MenuItem value="KG">KG</MenuItem>
                                        <MenuItem value="KRG">KRG</MenuItem>
                                        <MenuItem value="KTN">KTN</MenuItem>
                                        <MenuItem value="KIG">KIG</MenuItem>
                                        <MenuItem value="LBR">LBR</MenuItem>
                                        <MenuItem value="LSN">LSN</MenuItem>
                                        <MenuItem value="LTR">LTR</MenuItem>
                                        <MenuItem value="MTR">MTR</MenuItem>
                                        <MenuItem value="PAK">PAK</MenuItem>
                                        <MenuItem value="PCS">PCS</MenuItem>
                                        <MenuItem value="RCG">RCG</MenuItem>
                                        <MenuItem value="RIM">RIM</MenuItem>
                                        <MenuItem value="ROL">ROL</MenuItem>
                                        <MenuItem value="SET">SET</MenuItem>
                                        <MenuItem value="TBG">TBG</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Stok Minimal
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} sm={8} md={9}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            id="stok_minimal"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={stok_minimal}
                                            onChange={(event) => ubah_stok_minimal(event)}
                                            onBlur={(e) => perbaiki_stok_minimal(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                onChange={(event) => {
                                                    if (status === false) {
                                                        setStatus(true);
                                                        setLabelStatus("Aktif");
                                                    }
                                                    else {
                                                        setStatus(false);
                                                        setLabelStatus("Nonaktif");
                                                    }
                                                }}
                                            />
                                        }

                                        label={label_status}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<QrCodeScannerIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                        loading={proses}
                        onClick={() => setTampilDialogCetak(true)}
                    >
                        <Typography variant="body2" noWrap={true}>Barcode</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        <Typography variant="body2" noWrap={true}>{id_produk === "" ? "Simpan" : "Perbarui"}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        loading={proses}
                        onClick={() => setTampilDialogHapus(true)}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Produk
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={proses}
                        onClick={() => setTampilDialogHapus(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loading={proses}
                        onClick={() => {
                            setTampilDialogHapus(false);
                            setTampilDialog(false);
                            hapus();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_cetak}
                onClose={() => setTampilDialogCetak(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Jumlah Cetak
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Berapa baris barcode yang akan dicetak?
                    </DialogContentText>

                    <center>
                        <TextField
                            variant="outlined"
                            size="small"
                            inputProps={{
                                style: { textAlign: "center", width: "50px" }
                            }}
                            value={jumlah_cetak}
                            onChange={(event) => ubah_jumlah_cetak(event)}
                            onBlur={(e) => perbaiki_jumlah_cetak(e)}
                        />
                    </center>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<QrCodeScannerIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                        loading={proses}
                        onClick={() => cetak()}
                    >
                        <Typography variant="body2" noWrap={true}>Barcode</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialogCetak(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Produk);